import { useAuthStore } from "../modules/auth/store/auth";
import { useMainStore } from "./../stores/main";
import { setUserId, getAnalytics, Analytics } from "firebase/analytics";

let analytics: Analytics;

export function analyticsConnect() {
  try {
    /* eslint-disable */
    // @ts-ignore
    if ("__MODE__" !== "production") {
      analytics = getAnalytics();
      const authStore = useAuthStore();
      setUserId(analytics, authStore.email);
    }
  } catch (error) {
    console.log(error);
  }
}

/* function setScreen(to: any) {
  if (analyticsInstance) {
    analyticsInstance.setCurrentScreen(to.name);
    analyticsInstance.logEvent("page_view", {
      page_location: "https://app.farmhelp.dk",
      page_path: to.path,
      page_title: to.name
    });
    analyticsInstance.logEvent("screen_view", {
      app_name: isPWA() ? "pwa" : "web",
      screen_name: to.name,
      app_version: "v1.0.2"
    });
  } else {
    console.log("analytics is undefined");
  }
}, */
