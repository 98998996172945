import { FirebaseOptions } from 'firebase/app';

export type FirebaseEnvironment = 'production' | 'demo' | 'development';

interface FirebaseConfig {
  production: FirebaseOptions;
  demo: FirebaseOptions;
  development: FirebaseOptions;
}

const ENV_MAP = {
  production: 'PROD',
  demo: 'DEMO',
  development: 'DEV'
} as const;

function getEnvConfig(env: FirebaseEnvironment): FirebaseOptions {
  const envKey = ENV_MAP[env];
  console.log(`Loading Firebase config for ${envKey} environment`);
  
  const config = {
    apiKey: import.meta.env[`VITE_FIREBASE_API_KEY_${envKey}`],
    authDomain: import.meta.env[`VITE_FIREBASE_AUTH_DOMAIN_${envKey}`],
    projectId: import.meta.env[`VITE_FIREBASE_PROJECT_ID_${envKey}`],
    storageBucket: import.meta.env[`VITE_FIREBASE_STORAGE_BUCKET_${envKey}`],
    messagingSenderId: import.meta.env[`VITE_FIREBASE_MESSAGING_SENDER_ID_${envKey}`],
    appId: import.meta.env[`VITE_FIREBASE_APP_ID_${envKey}`],
    ...(envKey === 'PROD' && {
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID_PROD
    })
  };

  // Log the config for debugging (remove in production)
  console.log('Firebase Config:', {
    ...config,
    apiKey: config.apiKey ? '***' : undefined // Hide API key in logs
  });

  return config;
}

export const firebaseConfig: FirebaseConfig = {
  production: getEnvConfig('production'),
  demo: getEnvConfig('demo'),
  development: getEnvConfig('development')
}; 