import { initializeFirebase } from '@farmhelp/firebase';
import type { FirebaseEnvironment } from '@farmhelp/firebase';

export function fbConnect(mode: string) {
  console.log("App status - Connecting to Firebase");
  console.log("Current mode:", mode);
  
  // Map mode to environment
  const env: FirebaseEnvironment = 
    mode === 'development' || mode === 'dev' ? 'development' :
    mode === 'demo' ? 'demo' : 
    'production';

  console.log(`App environment - ${env}`);
  const { app, db } = initializeFirebase(env);
  return { app, db };
}





